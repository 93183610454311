<template>
  <div class="fill-default reset--container">
    <!-- Header -->
    <div class="header py-lg-5 log--header">
      <div class="separator separator-bottom separator-skew zindex-100 fill-default">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="sign--container">
      <div class="sign-left">
        <div class="form-container">
          <h1 class="text-white">
            Complete Test Coverage Now
          </h1>
          <!-- <form role="form" @submit.prevent="handleSubmit()">
            <div class="row">
              <div class="col-md-12 mb-3">
                <el-input class="input-event" placeholder="Enter event code" name="EventCode"
                  prefix-icon="fa fa-solid fa-hashtag" suffix-icon="el-icon-right" v-model="eventCode">
                </el-input>
              </div>
            </div>
          </form> -->
        </div>
      </div>
      <div class="sign--content">
        <div class="text-center">
          <router-link to="/">
            <img src="/img/brand/wring.png" width="120" />
          </router-link>
          <p class="mb-4 mt--2 nb--title">Wring</p>
          <h1 class="text-white mt-2">
            Accelerate your Innovation
          </h1>
        </div>
        <div class="mt-4">
          <div class="card bg-secondary border-0 mb-0" v-loading="loading">
            <div class="card-header bg-transparent pb-3">
              <div class="text-muted text-center mt-1 mb-2">
                <small>Sign in with</small>
              </div>
              <div data-netlify-identity-menu></div>
              <div class="btn-wrapper text-center">
                <a :href="githubUrl" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="/img/icons/common/github.svg" /></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a :href="googleUrl" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="/img/icons/common/google.svg" /></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-3">
              <div class="text-center text-muted mb-2">
                <small>Or reset your password</small>
              </div>
              <form role="form" @submit.prevent="handleSubmit()">
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <base-input alternative name="Email" type="email" required prepend-icon="ni ni-email-83"
                      placeholder="Email" v-model="email" />
                    <validation-error :errors="apiValidationErrors.email" />
                  </div>
                </div>

                <div class="text-center">
                  <base-button type="primary" native-type="submit" class="my-2 mx-5 mt-3"
                    :class="{ loading_btn: loading === true }" :disabled="!canSentLink">
                    <i v-if="loading" class="el-icon-loading mr-2"></i>
                    Send Password Reset Link
                  </base-button>
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <a href="/login" class="text-light"><small>Go to login page</small></a>
            </div>
            <div class="col-6 text-right">
              <a href="/register" class="text-light"><small>Create new account</small></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script
  type="text/javascript"
  src="https://identity.netlify.com/v1/netlify-identity-widget.js"
></script>

<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
export default {
  components: {
    ValidationError,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    email: "",
    eventCode:"",
    password: "",
    googleUrl:
      process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/oauth2/custom/" + "google",
    githubUrl:
      process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/oauth2/custom/" + "github",
  }),
  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated();
    },
  },
  methods: {
    async handleSubmit() {
      this.loading = true;

      const user = {
        email: this.email,
      };

      const requestOptions = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      if (this.$isDemo == 1) {
        await this.$notify({
          type: "danger",
          message: "Password reset is disabled in the demo.",
        });
        return;
      }
      try {
        const res = await this.$store.dispatch("forgotPassword", {user,requestOptions} );
        if(res.data && res.data.status == "success"){
          this.email = ""
          this.$notify({
            type: "success",
            message: res.data.message ,
          });
        }else{
          this.$notify({
            type: "danger",
            message: res.data.message,
          });
        }
      } catch (error) {
        console.log(error)
        await this.$notify({
          type: "danger",
          message: "We can't find a user with that e-mail address.",
        });
        this.setApiValidation(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    canSentLink() {
      if (this.apiValidationErrors.email) {
        console.log(this.apiValidationErrors.email[0] )
      }
      return this.email.length > 0 && this.apiValidationErrors
    },
  },
};
</script>

<style lang="scss">
.header-reset {
  padding-top: 0;
  margin-top: 0;
}

.reset--container {
  height: 100vh;
  overflow: hidden;
}

.nb--title {
  font-size: .9rem;
  color: #1a237e;
  text-transform: uppercase;
  font-weight: 700;
}

.log--header {
  min-height: 50vh;
}

.sign--container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: auto;

  .sign--content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    height: 100vh;
    width: 60vw;
  }
}

.loading_btn {
  cursor: not-allowed;
  filter: grayscale(0.55);

  &:hover {
    cursor: not-allowed !important;
    transform: none;
  }
}
.sign-left {
  width: 40%;
  height: 100%;
  background-color: #5E72E4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-event > input{
  height: 40px !important;
  border-radius: 24px !important;
}
.input-even > .el-input__suffix {
  border-radius: 24px;
    width: 40px;
    background-color: #5e98e4;
}
</style>
